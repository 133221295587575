import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import parse from 'html-react-parser'
import { MappedImage } from '../Image'

export const Footer = ({
  phoneNumber,
  phoneNumberExtras,
  hoursAndInfo,
  emailAddress,
  contactWrapperOne,
  contactWrapperTwo,
  footerNavOne,
  footerNavTwo,
  footerText,
  footerLogo,
  socialItems,
  copyrightText,
  payerFooter,
}) => (
  <footer className="footer">
    <Container fluid style={{ backgroundColor: '#2067b7', color: '#fff' }}>
      <Row className="footer-inner p-2 mx-auto" style={{ maxWidth: '1200px' }}>
        <Col sm={12} md={6} className="my-3" style={{ maxWidth: '1200px' }}>
          <div className="h-100 w-100 d-flex flex-column justify-content-center">
            <div>
              {footerLogo && (
                <MappedImage
                  style={{ maxHeight: '75px' }}
                  image={footerLogo}
                  alt={`${footerLogo?.title}`}
                />
              )}
            </div>
          </div>
        </Col>
        <Col sm={12} md={6} className="my-3 p-0">
          <div className="footer-contact-block">
            {contactWrapperOne && contactWrapperOne + ' '}
            <strong>
              <a href={'tel:' + phoneNumber}>{phoneNumber + ' '}</a>
            </strong>
            {phoneNumberExtras && ' ' + phoneNumberExtras}
            {hoursAndInfo && (
              <div className="hours-and-info-block">
                <p className="m-0">{parse(hoursAndInfo)}</p>
              </div>
            )}
            {contactWrapperTwo && ' ' + contactWrapperTwo + ' '}
            <strong>
              <a href={'mailto:' + emailAddress}>
                {emailAddress && emailAddress}
              </a>
            </strong>
          </div>
          <div className="footer-social-nav">
            {socialItems &&
              socialItems.map(item => {
                return (
                  <div
                    key={item.navigationTitle}
                    className="footer-social-nav-inner"
                  >
                    <a
                      href={item.navigationLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.navigationTitle && item.navigationTitle + '  '}

                      {item.socialIcon && (
                        <MappedImage
                          className="pl-1"
                          style={{ maxWidth: '80px' }}
                          src={item.socialIcon?.file.url}
                          alt=""
                        />
                      )}
                    </a>
                  </div>
                )
              })}
          </div>
        </Col>
      </Row>
    </Container>
    <Container
      fluid
      className="text-center py-5"
      style={{ backgroundColor: '#4a4a4a', color: '#fff' }}
    >
      <div className="mw">
        <Row>
          {payerFooter && parse(payerFooter.payerFooter)}
          {footerText && parse(footerText.footerText)}
        </Row>
        <Row
          className="footer-bottom-nav p-3 mx-auto d-inline"
          style={{ maxWidth: '1200px' }}
        >
          <nav className="nav justify-content-center">
            {footerNavOne &&
              footerNavOne.map(item => {
                return (
                  <a
                    key={item.navigationTitle}
                    href={item.navigationLink}
                    className="nav-link text-white"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.navigationTitle && item.navigationTitle}
                  </a>
                )
              })}
          </nav>
        </Row>
        <Row>
          {footerNavTwo &&
            footerNavTwo.map(item => {
              return (
                <Col key={item.navigationTitle}>
                  <a
                    href={item.navigationLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.navigationTitle && item.navigationTitle}
                  </a>
                </Col>
              )
            })}
        </Row>
        <Row>{copyrightText && parse(copyrightText.copyrightText)}</Row>
      </div>
    </Container>
  </footer>
)

export const mapFooterProps = props => {
  const {
    phoneNumber,
    phoneNumberExtras,
    hoursAndInfo,
    emailAddress,
    contactWrapperOne,
    contactWrapperTwo,
    footerNavOne,
    footerNavTwo,
    footerText,
    footerLogo,
    socialItems,
    copyrightText,
    payerFooter,
  } = props
  return {
    phoneNumber,
    phoneNumberExtras,
    hoursAndInfo,
    emailAddress,
    contactWrapperOne,
    contactWrapperTwo,
    footerNavOne,
    footerNavTwo,
    footerText,
    socialItems,
    copyrightText,
    footerLogo,
    payerFooter,
  }
}
